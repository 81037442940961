import React, { useState, useCallback } from 'react'
import { InvisibleSmartCaptcha } from '@yandex/smart-captcha';

import SaveButton from '../../forms/SaveButton';

import useApiQueries from '../../../hooks/useApiQueries';
import { changeState, validateEntryForms } from '../../forms/formActions';
import { IFormContainerProps, TFormData } from '../../../types/declarations';

import '../../../style/components/entryScreen/forms/formContainer.scss';
import useAPIv2 from '../../../hooks/useAPIv2';

const FormContainer = <T extends TFormData>(props: IFormContainerProps<T>) => {

  const {
    formData,
    setFormData,
    loadingStatus,
    setLoadingStatus = () => null,
    children,
    header,
    footer,
    buttonTitle,
    submitForm,
    addSmartCaptcha = false
  } = props;

  const [visibleCaptcha, setVisibleCaptcha] = useState(false);
  const [captchaKey, setCaptchaKey] = useState(0)
  const { checkToken } = useApiQueries();
  // const { checkToken } = useAPIv2()

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    changeState('', setFormData, 'commonError');
    if (formData && !validateEntryForms(formData, setFormData)) {
      return;
    }

    if (!addSmartCaptcha) {
      submitForm();
      return;
    }

    setLoadingStatus('loading');
    setVisibleCaptcha(true);
  }

  const validateCaptcha = async (token: string) => {
    // checkToken(token)
    //   .then((result) => {
    //     if (result === 'Passed') {
    //       submitForm();
    //     } else {
    //       changeState('Валидация не пройдена', setFormData, 'commonError');
    //       setLoadingStatus('error');
    //     }
    //     setCaptchaKey((p) => p + 1);
    //     setVisibleCaptcha(false);
    //   })
    checkToken({ token })
      .then((result) => {
        if (result === 200) {
          submitForm();
        } else {
          changeState('Валидация не пройдена', setFormData, 'commonError');
          setLoadingStatus('error');
        }
        //temp until captcha fixed
        // submitForm();
      })
      .catch(() => {
        changeState('Валидация не пройдена', setFormData, 'commonError');
        setLoadingStatus('error');
        submitForm();
      })
      .finally(() => {
        //@ts-ignore
        // window.smartCaptcha.reset();
        setCaptchaKey((p) => p + 1);
        setVisibleCaptcha(false);
      })
  }

  const handleChallengeHidden = useCallback(() => {
    setLoadingStatus('idle');
    setVisibleCaptcha(false)
  }, [setLoadingStatus]);

  return (
    <div className='form-container'>
      {header &&
        <div className="header">
          {header}
        </div>
      }
      <form onSubmit={handleSubmit}>
        <div className="children">
          {children}
          {(formData && ('commonError' in formData)) &&
            <span className="common-error">{formData.commonError}</span>
          }
        </div>
        <SaveButton
          buttonStatus={loadingStatus}
          setButtonStatus={setLoadingStatus}
          title={buttonTitle}
          customClassName={'submit-button'}
        />
      </form>
      <div className="footer">
        {footer}
      </div>
      {addSmartCaptcha &&
        <InvisibleSmartCaptcha
          key={captchaKey}
          sitekey="JWnKsP514dVYwA67SMAJvLXvawiazCtr3bBKKYWc"
          // test={true}
          onSuccess={(token) => validateCaptcha(token)}
          onChallengeHidden={handleChallengeHidden}
          visible={visibleCaptcha}
          hideShield={true}
        />
      }
    </div>
  )
}

export default FormContainer
