export const authData = {
    email: '',
    emailError: '',
    password: '',
    passwordError: '',
    commonError: ''
}

export const registerData = {
    name: '',
    nameError: '',
    email: '',
    emailError: '',
    phone: '',
    phoneError: '',
    password: '',
    passwordError: '',
    policy: false,
    policyError: '',
    commonError: ''
}

export const recoveryData = {
    email: '',
    emailError: '',
    commonError: ''
}

export const codeData = {
    code: '',
    codeError: ''
}

export const newPhoneData = {
    phone: '',
    phoneError: ''
}

export const innData = {
    inn: '',
    innError: ''
}

export const passwordInitialInfo = {
    oldPasswordValue: '',
    oldPasswordError: '',
    newPasswordValue: '',
    newPasswordError: '',
    newPassword2Value: '',
    newPassword2Error: '',
    commonError: ''
}

export const agreementData = {
    birthday: '01.01.1990',
    address: '',
    addressError: '',
    passportSeries: '',
    passportSeriesError: '',
    passportNumber: '',
    passportNumberError: '',
    passportData: '',
    passportDataError: '',
    recipient: '',
    recipientError: '',
    accountNumber: '',
    accountNumberError: '',
    recipientBank: '',
    recipientBankError: '',
    bik: '',
    bikError: '',
    corrAccount: '',
    corrAccountError: '',
    bankInn: '',
    bankInnError: '',
    kpp: '',
    kppError: '',
    swift: '',
    swiftError: '',
    head: '',
    headError: '',
}

export const agreementDataFullfilled = {
    birthday: '01.01.1970',
    birthdayError: '',
    address: 'г.Москва ул. Москва. д. 1. кв. 1',
    addressError: '',
    passportSeries: '1111',
    passportNumber: '123456',
    passportError: '',
    passportData: 'Отделом УФМС по гор Москва по району Москва',
    passportDataError: '',
    recipient: 'Иванов Иван Петрович',
    recipientError: '',
    accountNumber: '123456789',
    accountNumberError: '',
    recipientBank: 'ПАО Сбербанк УЛьяновск',
    recipientBankError: '',
    bik: '1234567890',
    bikError: '',
    corrAccount: '12345678901234567890',
    corrAccountError: '',
    bankInn: '1234567890',
    bankInnError: '',
    kpp: '1234567890',
    kppError: '',
    swift: 'DFGRRUMM',
    swiftError: ''
}