export const tempPollsResults = [
    {
        orderId: 'ФГМ00012464',
        dealerId: 1,
        answers: [0, 0, 0, 1, 1, 1, 0, 0, 0, 1, 1, 1],
        date: '19.01.2024'
    },
    {
        orderId: 'РЭБ-000-уацуа',
        dealerId: 1,
        answers: [1, 1, 0, 1, 1, 1, 0, 0, 0, 1, 1, 1],
        date: '02.03.2023'
    },
    {
        orderId: 'ФГМ00012467',
        dealerId: 1,
        answers: [0, 0, 0, 1, 0, 1, 0, 0, 0, 1, 1, 1],
        date: '29.12.2022'
    },

]

export const tempDealerRating = {
    dealerId: 1,
    rating: 3.4
}

export const tempWalletInfo = {
    balance: 1500
}

export const tempWalletHistory = {
    income: [
        {
            amount: 1500,
            date: '10.03.2023'
        },
        {
            amount: 500,
            date: '10.02.2023'
        },
        {
            amount: 1000,
            date: '10.01.2023'
        },
    ],
    outcome: [
        {
            amount: 1500,
            date: '15.03.2023'
        },
    ]
}

export const tempDealerLlist = [
    {
        id: 1,
        name: 'ООО АВТОМОБИЛЬ'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО дилер'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО завод'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО монета'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО кружка'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО чашка'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО теплофон'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО резка'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО топка'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО АВТОМОБИЛЬ'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО дилер'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО завод'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО монета'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО кружка'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО чашка'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО теплофон'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО резка'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО топка'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО АВТОМОБИЛЬ'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО дилер'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО завод'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО монета'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО кружка'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО чашка'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО теплофон'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО резка'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО топка'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО АВТОМОБИЛЬ'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО дилер'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО завод'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО монета'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО кружка'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО чашка'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО теплофон'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО резка'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО топка'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО АВТОМОБИЛЬ'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО дилер'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО завод'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО монета'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО кружка'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО чашка'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО теплофон'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО резка'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО топка'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО АВТОМОБИЛЬ'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО дилер'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО завод'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО монета'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО кружка'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО чашка'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО теплофон'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО резка'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО топка'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО АВТОМОБИЛЬ'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО дилер'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО завод'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО монета'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО кружка'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО чашка'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО теплофон'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО резка'
    },
    {
        id: Math.floor(Math.random() * 100000),
        name: 'ООО топка'
    },
]
