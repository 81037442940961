import useUtil from '../useUtil';
import { VinInfo } from './types';
import { VinInfoParams } from '../data/useVinInfo';

export const useTradeinApi = () => {
  const { handleUserRequest } = useUtil();

  const getVinInfo = async (payload: VinInfoParams): Promise<VinInfo | null> => {
    return await handleUserRequest({
      path: 'TradeIn/GetVinInfo',
      tokenType: 'token',
      payload,
    });
  };

  return {
    getVinInfo,
  };
};
