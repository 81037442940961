import React, { useState, useEffect, createContext, useRef } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import useApiQueries from "../hooks/useApiQueries";

import {
  IUserContextType,
  IDealer,
  IDealerContextType
} from "../types/declarations";
import '../style/util/requireAuth.scss';
import { useUser } from "../hooks/data/useUser";
import { UserInfo } from "../hooks/api/types";
import { useQueryClient } from "react-query";

interface IRequireAuth {
  children: React.ReactNode,
  //TODO change to isMasterToken
  token?: string,
  navigateTo?: string
}

export const UserContext = createContext<IUserContextType<UserInfo> | null>(null);
export const DealerContext = createContext<IDealerContextType<IDealer[]> | null>(null);

const RequireAuth = ({ children, token, navigateTo }: IRequireAuth) => {

  const tokenToCheck = token ? token : 'token';
  const navigate = useNavigate();
  const currentToken = localStorage.getItem(tokenToCheck) ?? null;
  const currentTokenRef = useRef(currentToken);

  const queryClient = useQueryClient();
  const { getDealersList } = useApiQueries();
  const { data, isFetching, isError } = useUser(currentToken);
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [dealers, setDealers] = useState<IDealer[] | null>(null);
  const [loading, setLoading] = useState('loading');

  if (currentToken !== currentTokenRef.current) {
    currentTokenRef.current = currentToken;
    queryClient.resetQueries();
  }

  useEffect(() => {
    if (currentToken === null) {
      navigate('/');
      return;
    }
    if (tokenToCheck === 'masterToken') {
      setLoading('idle')
      return;
    }

    if (isError) {
      setLoading('error');
      return;
    }

    if (!isFetching && data) {
      setUserInfo(data);
    }

    if (tokenToCheck === 'token' && loading === 'loading') {
      getDealersList()
        .then((dealersList) => {
          setDealers(dealersList);
          setLoading('idle');
        })
        .catch(() => {
          setDealers([]);
        })
        .finally(() => {
          setLoading('idle');
        })
    }
    // eslint-disable-next-line
  }, [loading, tokenToCheck, data, isFetching, isError])

  if (currentToken === null) {
    return <Navigate to={navigateTo ? navigateTo : '/'} />;
  }

  const prepareContent = () => {
    switch (loading) {
      case 'idle':
        return (
          <UserContext.Provider value={{ userInfo, setUserInfo }}>
            <DealerContext.Provider value={{ dealers, setDealers }}>
              {children}
            </DealerContext.Provider>
          </UserContext.Provider>
        );
      case 'loading':
        return (
          <div className="require-auth-loading">
            <span>
              Загружаем информацию...
            </span>
          </div>
        );
      case 'error':
        return (
          <div className="require-auth-error">
            <span>
              В ходе загрузки произошла ошибка. Попробуйте перезагрузить страницу...
            </span>
          </div>
        );
      default:
        return 'Загружаем информацию...';
    }
  }

  return (
    <>
      {prepareContent()}
    </>
  );
}

export default RequireAuth
