import { createTheme } from '@mui/material';
import { ruRU } from '@mui/material/locale';
import { ruRU as ruRUDate } from '@mui/x-date-pickers/locales';
import { typography } from './typography';
import { palette } from './palette';
import { breakpoints } from './breakpoints';

export const theme = createTheme(
  {
    palette: {
      primary: {
        main: palette.main,
      },
      ...palette,
    },
    breakpoints,
    typography,
  },
  ruRU,
  ruRUDate,
)