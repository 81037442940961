import React, { ClipLoader } from "react-spinners";

const Spinner = () => {

    const styles = {
        position: 'absolute',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    } as const

    return (
        <div style={styles}>
            <ClipLoader
                color={'#484848'}
                size={200}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </div>
    )
}

export default Spinner;