import React, { lazy, Suspense, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Routes, Route } from 'react-router-dom';

import RequireAuth from '../util/RequireAuth';
import RedirectPage from '../util/RedirectPage';
import Spinner from '../spinner/Spinner';
import EntryScreen from './entryScreen/EntryScreen';
import '../style/components/app.scss';
import { ThemeProvider, theme } from '../uikit/theme';

const Master = lazy(() => import('./master/Master'));
const MasterLogin = lazy(() => import('./master/MasterLogin'));
const Poll = lazy(() => import('./Poll'));
const Verify = lazy(() => import('./Verify'));

const SideBar = lazy(() => import('./sideBar/SideBar'));
const News = lazy(() => import('./news/News'));
const Account = lazy(() => import('./account/Account'));
const Rating = lazy(() => import('./rating/Rating'));
const Wallet = lazy(() => import('./wallet/Wallet'));
const Learning = lazy(() => import('./learning/Learning'));
const Contracts = lazy(() => import('./contracts/Contracts'));
const Faq = lazy(() => import('./faq/Faq'));
const TradeIn = lazy(() => import('./TradeIn').then((module) => ({ default: module.TradeIn })));

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, keepPreviousData: true } },
});

const App = () => {
  useEffect(() => {
    const handleResize = () => {
      // let vh = window.innerHeight * 0.01;
      // document.documentElement.style.setProperty('--vh', `${vh}px`);
      document.documentElement.style.setProperty('--doc-height', `${window.innerHeight}px`);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route path="/" element={<EntryScreen />} />
          <Route path="/poll/:pollId" element={<Poll />} />
          <Route path="/verification/:verification" element={<Verify />} />
          <Route path="/masterpage/" element={<MasterLogin />} />
          <Route
            path="/masterpage/loggedin"
            element={
              <RequireAuth token={'masterToken'} navigateTo={'/masterpage/'}>
                <Master />
              </RequireAuth>
            }
          />
          <Route
            path="/*"
            element={
              <RequireAuth>
                <div className="app-content">
                  <SideBar />
                  <Suspense fallback={<Spinner />}>
                    <Routes>
                      {/* <Route path="/news" element={<News />} /> */}
                      <Route path="/account" element={<Account />} />
                      <Route path="/account/:menu" element={<Account />} />
                      {/* <Route path="/rating" element={<Rating />} /> */}
                      {/* <Route path="/profits" element={<Wallet />} /> */}
                      {/* <Route path="/learning" element={<Learning />} /> */}
                      {/* <Route path="/tradein" element={<TradeIn />} /> */}
                      {/* <Route path="/faq" element={<Faq />} /> */}
                      {/* <Route path="/faq/:questionId" element={<Faq />} /> */}
                      {/* <Route path="/contracts/" element={<Contracts />} /> */}
                      {/* <Route path="/contracts/:menu" element={<Contracts />} /> */}
                      <Route path="/*" element={<RedirectPage />} />
                      {/* <Route path="/agreement" element={<Agreement data={agreementDataFullfilled}/>} /> */}
                    </Routes>
                  </Suspense>
                </div>
              </RequireAuth>
            }
          />
        </Routes>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
