import React, { useState } from 'react'

import {
    IntroForm,
    AuthForm,
    RegisterForm,
    RecoveryForm
} from './forms';

import '../../style/components/entryScreen/entryScreen.scss';

const EntryScreen = () => {
    const [activeForm, setActiveForm] = useState(0);

    const displayForm = () => {
        switch (activeForm) {
            case 0:
                return (
                    <IntroForm
                        setActiveForm={setActiveForm}
                    />
                )
            case 1:
                return (
                    <AuthForm
                        setActiveForm={setActiveForm}
                    />
                )
            case 2:
                return (
                    <RegisterForm
                        setActiveForm={setActiveForm}
                    />
                )
            case 3:
                return (
                    <RecoveryForm
                        setActiveForm={setActiveForm}
                    />
                )
            default:
                return (
                    <IntroForm
                        setActiveForm={setActiveForm}
                    />
                )
        }
    }

    return (
        <div className='entry-container'>
            {displayForm()}
        </div>
    )
}

export default EntryScreen
