import useUtil from "../useUtil";

export const useAuthApi = () => {

    const { handleUserRequest } = useUtil();

    const registerUser = async (payload: any) => {
        return handleUserRequest({
            path: 'Registration/RegisterUser', 
            payload,
        });
    }

    const verificationAppeal = async (payload: any) => {
        return handleUserRequest({
            path: 'Authorization/VerificationAppeal', 
            payload,
        });
    }
    
    const verificationConfirmation = async (payload: any) => {
        return handleUserRequest({
            path: 'Authorization/VerificationConfirmation', 
            payload,
        });
    }

    const authorizeUser = async (payload: any, tokenType:string = 'token') => {
        const result = await handleUserRequest({
            path: 'Authorization/Authorize',
            payload,
        })
        //@ts-ignore
        if (result.token) {
            //@ts-ignore
            localStorage.setItem(tokenType, result.token)
            return 'success';
        }
        return result;

        // w/o back
        // sessionStorage.setItem('token', 'ewfewfewfewf')
        // return 'success'
    }

    const recoveryPassword = async () => {
        // return handleDefaultUserAxiosRequestWithToken('user/info', {}, _baseAPI, 'token', (result) => {
        //     if (result.data.user.email) {
        //         localStorage.setItem('user', JSON.stringify(result.data.user));
        //     }
        // });

        return 'success'
    }

    const confirmAction = async () => {
        // return handleDefaultUserAxiosRequestWithToken('user/info', {}, _baseAPI, 'token', (result) => {
        //     if (result.data.user.email) {
        //         localStorage.setItem('user', JSON.stringify(result.data.user));
        //     }
        // });

        return 'success'
    }

    const checkToken = async (payload: {token: string}) => {
        // const ipAddress = await axios.get('https://geolocation-db.com/json/');
        return await handleUserRequest({
            path: 'Authorization/ValidateCaptcha',
            payload
        })
    }

    return {
        registerUser,
        authorizeUser,
        recoveryPassword,
        confirmAction,
        checkToken,
        verificationAppeal,
        verificationConfirmation,
    }
}
