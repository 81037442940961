import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { TButtonStatuses } from '../../types/declarations';
import ClipLoader from "react-spinners/ClipLoader";

import '../../style/components/forms/saveButton.scss';

interface ISaveButton {
  buttonStatus: TButtonStatuses,
  setButtonStatus?: Dispatch<SetStateAction<TButtonStatuses>>
  disabled?: boolean,
  customClassName?: string,
  customStyles?: React.CSSProperties,
  actionFunc?: () => void,
  title?: string,
  type?: "submit" | "button" | "reset" | undefined
}

const SaveButton = (props: ISaveButton) => {
  const {
    customClassName = '',
    buttonStatus,
    setButtonStatus,
    disabled = buttonStatus !== 'idle',
    actionFunc = () => null,
    title = 'Сохранить',
    type = 'submit',
    customStyles,
  } = props;

  const timeoutId = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (
      (buttonStatus === 'success' || buttonStatus === 'error') &&
      typeof setButtonStatus === 'function'
    ) {
      timeoutId.current = setTimeout(() => {
        setButtonStatus('idle');
      }, 1500)
    }
    return () => {
      clearTimeout(timeoutId.current);
      timeoutId.current = undefined;
    }
  }, [buttonStatus]);

  const displayContent = () => {
    switch (buttonStatus) {
      case 'idle':
        return title;
      case 'loading':
        return (
          <ClipLoader
            color={'white'}
            size={20}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        )
      case 'success':
        return (<div className="success-mark"></div>);
      case 'error':
        return (
          <>
            <div className="error-line1"></div>
            <div className="error-line2"></div>
          </>
        )
      default:
        return title;
    }
  }
  return (
    <div className={`save-button-container ${customClassName}`} style={customStyles}>
      <button
        className={`save-button ${buttonStatus}`}
        onClick={actionFunc}
        type={type}
        disabled={disabled}
      >
        {displayContent()}
      </button>
    </div >
  )
}

export default SaveButton
