import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import FormContainer from './FormContainer';
import Input from '../../forms/Input';
import CodeForm from './CodeForm';

import useApiQueries from '../../../hooks/useApiQueries';
import { authData } from '../../data/formsInitialinfo';
import { IEntryProps, TFormAuthData, TButtonStatuses } from '../../../types/declarations';
import { changeState } from '../../forms/formActions';

import '../../../style/components/entryScreen/forms/authForm.scss';
import useAPIv2 from '../../../hooks/useAPIv2';

const AuthForm = ({ setActiveForm }: IEntryProps) => {

  const [formData, setFormData] = useState<TFormAuthData>(authData);
  const [confirmStage, setConfirmStage] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState<TButtonStatuses>('idle');
  const [repeatSended, setRepeatSended] = useState(false);

  const { authorizeUser, verificationAppeal } = useApiQueries();
  const navigate = useNavigate();

  const createFooter = () => {
    return (
      <div className='auth-footer'>
        <div
          className="link"
          onClick={() => setActiveForm(2)}
        >
          Зарегистрироваться
        </div>
        <div
          className="link"
          onClick={() => setActiveForm(3)}
        >
          Восстановить пароль
        </div>
      </div>
    )
  }

  const doAuthorization = () => {
    changeState('', setFormData, 'commonError')
    setLoadingStatus('loading');
    authorizeUser({
      login: formData.email,
      password: formData.password,
      clientType: 1
    })
      .then((res) => {
        setLoadingStatus('idle');
        if (res.error) {
          changeState(res.error, setFormData, 'commonError')
          return;
        }
        if (res === 'success') {
          // setConfirmStage(true);
          navigate('/account')
          // closeConfirm();
        } else {
          changeState('Произошла ошибка', setFormData, 'commonError')
        }
      })
      .catch(() => {
        changeState('Произошла ошибка', setFormData, 'commonError')
        setLoadingStatus('error');
      })
  }

  const repeatConfirmation = () => {
    changeState('', setFormData, 'commonError')
    setLoadingStatus('loading')
    verificationAppeal({
      ClientType: 1,
      Specification: 1,
      Email: formData.email,
      ServiceType: 2
    }).then((res) => {
      if (res.error || res !== 200) {
        changeState(res.error ?? 'Произошла ошибка', setFormData, 'commonError');
        setLoadingStatus('error');
        return;
      }
      setLoadingStatus('idle');
      setRepeatSended(true);
    }).catch(() => {
      setLoadingStatus('error');
      changeState('Произошла ошибка', setFormData, 'commonError')
    });
  }

  const returnBack = useCallback(() => {
    setConfirmStage(false);
  }, []);

  const closeConfirm = useCallback(() => {
    localStorage.setItem('token', 'asfafsasfasf');
    navigate('/account');
  }, [navigate]);

  return (
    <>
      {
        repeatSended ?
          <>
            <CodeForm
              returnBackFunc={returnBack}
              confirmedFunc={() => setRepeatSended(false)}
              confirmedText='Просьба пройти по ссылке, отправленной на указанный почтовый адрес для его подтверждения'
              header={'Подтверждение почты'}
              tempConfirmed={true}
            />
          </>
          :
          <>
            {confirmStage ?
              <CodeForm
                returnBackFunc={returnBack}
                confirmedFunc={closeConfirm}
                confirmType={'authorization'}
                confirmedText=''
                header={'Авторизация'}
              />
              :
              <FormContainer
                formData={formData}
                setFormData={setFormData}
                loadingStatus={loadingStatus}
                setLoadingStatus={setLoadingStatus}
                addSmartCaptcha={true}
                submitForm={doAuthorization}
                header={'Авторизация'}
                footer={createFooter()}
                buttonTitle={'Войти'}
              >
                <Input
                  label={'Почта'}
                  value={formData.email}
                  setValue={(newValue) => changeState(newValue, setFormData, 'email')}
                  placeholder={'Введите e-mail'}
                  customClassName={'es-input'}
                  error={formData.emailError}
                  type='email'
                />
                <Input
                  label={'Пароль'}
                  value={formData.password}
                  setValue={(newValue) => changeState(newValue, setFormData, 'password')}
                  placeholder={'Введите пароль'}
                  customClassName={'es-input'}
                  type='password'
                  error={formData.passwordError}
                />
                {formData.commonError === 'Необходимо подтвердить почту' &&
                  <div
                    className="auth-confirm"
                    onClick={repeatConfirmation}
                  >
                    Отправить подтверждение повторно
                  </div>
                }
              </FormContainer>
            }
          </>
      }
    </>
  )
}

export default AuthForm
