import React, { useEffect, useState, useRef } from 'react';

import Input from '../../forms/Input';
import FormContainer from './FormContainer';

import useApiQueries from '../../../hooks/useApiQueries';
import { changeState, validateEntryForms, timeBeautify } from '../../forms/formActions';
import { codeData } from '../../data/formsInitialinfo';
import { TButtonStatuses, TFormCodeData } from '../../../types/declarations';

import '../../../style/components/entryScreen/forms/codeForm.scss';

interface ICodeFormProps {
    returnBackFunc?: () => void | undefined,
    confirmedFunc: () => void | undefined,
    confirmedText: string,
    confirmType?: string,
    header?: string,
    tempConfirmed?: boolean
}

const initialTimer = 10;

const CodeForm = (props: ICodeFormProps) => {

    const {
        returnBackFunc,
        confirmedFunc,
        confirmedText,
        confirmType,
        header = '',
        tempConfirmed = false
    } = props;

    const [formData, setFormData] = useState<TFormCodeData>(codeData);
    const [loadingStatus, setLoadingStatus] = useState<TButtonStatuses>('idle');
    const [confirmed, setConfirmed] = useState(tempConfirmed);
    const [repeatSms, setRepeatSms] = useState(initialTimer);
    const refTimer = useRef<ReturnType<typeof setInterval> | null>(null);
    const { confirmAction } = useApiQueries();

    const startTimer = () => {
        setRepeatSms(prevState => 10);
        const interval = setInterval(() => {
            setRepeatSms(prevState => prevState - 1);
        }, 1000);
        refTimer.current = interval;
    }

    useEffect(() => {
        if (!refTimer.current && repeatSms > 0) {
            startTimer();
        }

        if (repeatSms <= 0 && refTimer.current) {
            clearInterval(refTimer.current);
        }
    }, [repeatSms])

    const submitConfirm = () => {
        if (tempConfirmed) {
            confirmedFunc();
        } else {
            if (validateEntryForms(formData, setFormData)) {
                setLoadingStatus('loading');
                confirmAction()
                    .then((res) => {
                        if (res) {
                            setTimeout(() => {
                                setConfirmed(true);
                                setLoadingStatus('idle');
                                if (confirmType === 'authorization') {
                                    confirmedFunc();
                                }
                            }, 1000)
                        } else {
                            changeState("Введен неверный код", setFormData, 'codeError');
                        }
                    })
            }
        }
    }

    return (
        <FormContainer
            formData={!(confirmed || tempConfirmed) ? formData : undefined}
            setFormData={setFormData}
            loadingStatus={loadingStatus}
            header={header}
            footer={<></>}
            buttonTitle={confirmed ? 'ОК' : 'Подтвердить'}
            submitForm={submitConfirm}
        >
            {confirmed ?
                <>
                    <div className="cf-confirmed-text">
                        {confirmedText}
                    </div>
                </>
                :
                <>
                    {returnBackFunc && <div
                        className="cf-return"
                        onClick={returnBackFunc}
                    >
                        <i className="fa-solid fa-arrow-left"></i>
                    </div>}
                    <Input
                        label={'На ваш номер телефона отправлен код подтверждения'}
                        value={formData.code}
                        setValue={(newValue) => changeState(newValue, setFormData, 'code')}
                        placeholder={'Введите 6-значный код'}
                        customClassName={'es-input cf-input'}
                        error={formData.codeError}
                        inputFor='passportNumber'
                    />
                    {repeatSms ?
                        <span className="cf-repeat-sms">{`Отправить код повторно: ${timeBeautify(repeatSms)}`}</span>
                        :
                        <span
                            className='cf-repeat-sms-button'
                            onClick={startTimer}
                        >
                            Отправить код повторно
                        </span>
                    }
                </>
            }
        </FormContainer>
    )
}

export default CodeForm
