import useUtil from "../useUtil";

export const usePollApi = () => {

    const { handleUserRequest } = useUtil();
    const _baseAPI = 'https://exeedservice.ru/apicall/';

    const getSurveyData = async (payload: any) => {
        const result = await handleUserRequest({
            path: 'Feedbacks/GetSurveyData',
            payload,
        }); 
        if (!result) {
            return null;
        }
        if (result.error) {
            return result;
        }
        return {
            order: result.externalOrderId,
            masters: result.masters,
            dealerId: result.dealer?.id,
        }
    }

    const passSurvey = async (payload: any) => {
        return handleUserRequest({
            path: 'Feedbacks/PassSurvey',
            payload,
        }); 
    }

    return {
        getSurveyData,
        passSurvey,
    }
}
