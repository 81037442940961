import useUtil from "../useUtil";

export const useDealerApi = () => {

    const { handleUserRequest } = useUtil();

    const getDealersList = async () => {
        return handleUserRequest({
            baseUrl: 'https://exeedservice.ru/apicall',
            path: '/newmaster/dealers',
        });
    }

    return {
        getDealersList
    }
}
