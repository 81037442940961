export const BASE_URL = 'https://exeedservice.ru/api/';
// export const BASE_URL = 'http://devzone.exeedservice.ru/api/';
// export const BASE_URL = 'http://172.23.17.107:50000/exeed/';

export enum queryKeys {
  GET_USER_INFO = 'GET_USER_INFO',
  CHANGE_USER_INFO = 'CHANGE_USER_INFO',
  ADD_DOCUMENT = 'ADD_DOCUMENT',
  GET_DOCUMENTS = 'GET_DOCUMENTS',
  GET_MASTER_DOCUMENTS = 'GET_MASTER_DOCUMENTS',
  GET_RATING = 'GET_RATING',
  GET_AGENTS = 'GET_AGENTS',
  GET_WALLET = 'GET_WALLET',
  GET_MASTERS_WALLET_INFO = 'GET_MASTERS_WALLET_INFO',
}

export const TEMP_USER_DATA = {
  userName: 'Oleg',
  userPhone: '79991112233',
  userEmail: 'oleg.arl@yandex.ru',
  userDC: 1,
  emailVerified: true,
  phoneVerified: false,
  contractExists: false,
  imageExists: false,
  inn: '',
  isVerified: false,
};

export const TEMP_RATING_DATA = {
  dates: 'ПРИМЕР',
  userId: 'userId',
  userName: 'Olegsei',
  dealerName: 'ООО ДЦ',
  dealerId: 999,
  questionPercents: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
  totalQuestionPercents: 100,
  questionScore: [16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16],
  totalQuestionScore: 144,
  commercialPollPercent: 36,
  warrantyPollPercent: 0,
  commercialPollAmount: 32,
  warrantyPollAmount: 0,
  totalCommercialOrders: 88,
  totalWarrantyOrders: 0,
  lostServiceCompanies: 10,
  scoreQuality: 574.25,
  scoreNonmissedCompanies: 25,
  scoreTotal: 599.24,
  rating: 6,
  amountOfMasters: 262,
  dealerCategory: 2,
  dealerCategoryBonus: 7,
  bonusForQuality: 4195,
  bonusForOrders: 9475,
  bonusTotal: 13760,
  maxBonusForQuality: 7000,
  maxBonusForOrders: 13200,
  missedBonus: 6530,
};
