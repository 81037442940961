import {
  useDealerApi,
  usePollApi,
  useWalletApi,
  useAuthApi,
  useUserApi,
  useVideoApi,
  useDocumentsApi,
  useTradeinApi,
} from './api';

const useApiQueries = () => {
  const { getDealersList } = useDealerApi();
  const pollApis = usePollApi();
  const walletApis = useWalletApi();
  const authApis = useAuthApi();
  const userApis = useUserApi();
  const videoApis = useVideoApi();
  const documentsApi = useDocumentsApi();
  const tradeinApi = useTradeinApi();

  return {
    getDealersList,
    ...pollApis,
    ...walletApis,
    ...authApis,
    ...userApis,
    ...videoApis,
    ...documentsApi,
    ...tradeinApi,
  };
};

export default useApiQueries;
