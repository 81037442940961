import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const RedirectPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/account');
    });

    return (
        <>

        </>
    )
}

export default RedirectPage
