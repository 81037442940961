import React, { useState } from 'react';

import FormContainer from './FormContainer';
import Input from '../../forms/Input';
import CodeForm from './CodeForm';

import { recoveryData } from '../../data/formsInitialinfo';
import { IEntryProps, TFormRecoveryData, TButtonStatuses } from '../../../types/declarations';
import { changeState } from '../../forms/formActions';

import '../../../style/components/entryScreen/forms/recoveryForm.scss';
import useAPIv2 from '../../../hooks/useAPIv2';
import useApiQueries from '../../../hooks/useApiQueries';

const RecoveryForm = ({ setActiveForm }: IEntryProps) => {

    const [formData, setFormData] = useState<TFormRecoveryData>(recoveryData);
    const [confirmStage, setConfirmStage] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState<TButtonStatuses>('idle');
    const { verificationAppeal } = useApiQueries();

    const createFooter = () => {
        return (
            <div className='recf-footer'>
                <div
                    className="link"
                    onClick={() => setActiveForm(1)}
                >
                    Войти
                </div>
                <div
                    className="link"
                    onClick={() => setActiveForm(2)}
                >
                    Зарегистрироваться
                </div>
            </div>
        )
    }


    const submitForm = () => {
        changeState('', setFormData, 'commonError')
        verificationAppeal({
            ClientType: 1,
            Specification: 3,
            Email: formData.email.toLowerCase(),
            ServiceType: 2
        })
            .then((response) => {
                if (response === 200) {
                    setConfirmStage(true);
                    setLoadingStatus('success');
                } else {
                    changeState(response.error ?? 'Произошла ошибка', setFormData, 'commonError');
                    setLoadingStatus('error');
                }
            })
            .catch(() => setLoadingStatus('error'))
            .finally(() => setLoadingStatus('idle'))
    }

    const returnBack = () => {
        setConfirmStage(false);
    }

    const closeConfirm = () => {
        setActiveForm(1);
    }


    return (
        <>
            {confirmStage ?
                <CodeForm
                    returnBackFunc={returnBack}
                    confirmedFunc={closeConfirm}
                    confirmedText='Инструкции по восстановлению пароля отправлены на почту'
                    header={'Восстановление пароля'}
                    tempConfirmed={true}
                />
                :
                <FormContainer
                    formData={formData}
                    setFormData={setFormData}
                    loadingStatus={loadingStatus}
                    setLoadingStatus={setLoadingStatus}
                    addSmartCaptcha={true}
                    submitForm={submitForm}
                    header={'Восстановление пароля'}
                    footer={createFooter()}
                    buttonTitle={'Восстановить'}
                >
                    <Input
                        label={'Введите email, который вы использовали при регистрации'}
                        value={formData.email}
                        setValue={(newValue) => changeState(newValue, setFormData, 'email')}
                        placeholder={'Введите e-mail'}
                        customClassName={'es-input recf-input'}
                        error={formData.emailError}
                        type='email'
                    />
                </FormContainer>
            }
        </>
    )
}

export default RecoveryForm
