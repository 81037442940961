import { IDealer, TFormData } from "../../types/declarations";
import testPDF from '../../documents/test.pdf';

type setWithPrevState = (prevState: any) => any

type Tvalue = any;
type TsetFunc = (func: setWithPrevState) => void;
type TpropertyToSet = string;

export const changeState = (value: Tvalue, setFunc: TsetFunc, propertyToSet: TpropertyToSet): void => {
    setFunc((prevState) => {
        let newState = Object.assign(prevState);
        newState = {
            ...newState,
            [propertyToSet]: value
        }
        return newState;
    })
}


export const checkIsArrayOfStrings = (value: unknown): value is string[] => {
    return Array.isArray(value)
        && value.length > 0
        && value.every(item => typeof item === "string");
}

export const getCurrentDate = (): string => {
    const currentDate = new Date();
    return `${makeNumberDouble(currentDate.getDate())} ${months[currentDate.getMonth()]} ${currentDate.getFullYear()}`;
}

export const dateBeautify = (date: string): string => {
    const splitDate = date.split('.');
    return `${makeNumberDouble(+splitDate[0])} ${months[+splitDate[1] - 1]} ${splitDate[2]}`
}

export const timeBeautify = (value: number): string => {
    const minutes = Math.floor(value / 60);
    const seconds = value % 60;
    return `${makeNumberDouble(minutes)}:${makeNumberDouble(seconds)}`
}

export const phoneBeautify = (value: string): string => {
    let newPhone = value.replace(/\D+/g, '');
    switch (newPhone.charAt(0)) {
        case '7':
            break;
        case '8':
            newPhone = `7${newPhone.slice(1)}`;
            break;
        default:
            newPhone = `7${newPhone}`;
            break;
    }
    return `+${newPhone.slice(0,1)} (${newPhone.slice(1,4)}) ${newPhone.slice(4,7)}-${newPhone.slice(7,9)}-${newPhone.slice(9,11)}`
}

export const makeNumberDouble = (value: number): string => {
    const stringValue = value.toString();
    return stringValue.length === 1 ? `0${stringValue}` : stringValue
}

export const getDealerName = (dealers: IDealer[] | null, dealerId: number = -1): string => {
    if (dealers) {
        return dealers.filter(dealer => dealer.Id === dealerId)[0].Name
    };
    return '';
}

export const openInstruction = () => {
    const pdfWindow = window.open();
    if (pdfWindow) {
        pdfWindow.location.href = testPDF;
    }
}

export const validateEntryForms = <T extends TFormData>(formData: T, setFunc: TsetFunc): boolean => {
    let validationResult = true;
    if ('name' in formData && !formData.name.match(/^[a-zA-Zа-яА-Я ]{2,100}$/)) {
        changeState("Длина имени должна быть больше 5 символов и содержать только буквы", setFunc, 'nameError');
        validationResult = false;
    } else {
        changeState("", setFunc, 'nameError');
    }

    if ('policy' in formData && !formData.policy) {
        changeState("Необходимо согласиться с политикой конфиденциальности", setFunc, 'policyError');
        validationResult = false;
    } else {
        changeState("", setFunc, 'policyError');
    }

    if ('email' in formData && !formData.email.match(/^[\w.-]+@[.a-zA-Z0-9_-]+?\.[a-zA-Z]{2,5}$/)) {
        changeState("e-mail введен неверно", setFunc, 'emailError');
        validationResult = false;
    } else {
        changeState("", setFunc, 'emailError');
    }

    if ('phone' in formData) {
        const plainPhone = formData.phone.replace(/ |\(|\)|-/ig, '');
        if (!plainPhone.match(/^\+7[0-9]{10}$/)) {
            changeState("Телефон введен неверно. Формат: +7 (925) 111-11-11", setFunc, 'phoneError');
            validationResult = false;
        } else {
            changeState("", setFunc, 'phoneError');
        }
    }

    if ('dc' in formData && formData.dc === -1) {
        changeState('Выберите дилерский центр', setFunc, 'dcError');
        validationResult = false;
    } else {
        changeState('', setFunc, 'dcError');
    }

    if ('inn' in formData && !formData.inn.match(/^\d{10,12}$/)) {
        changeState('ИНН должен состоять из 10-12 цифр', setFunc, 'innError')
        validationResult = false;
    } else {
        changeState('', setFunc, 'innError')
    }

    if ('password' in formData && !formData.password.match(/^.{6,20}$/)) {
        changeState("Длина пароля должна быть от 6 до 20 символов", setFunc, 'passwordError');
        validationResult = false;
    } else {
        changeState("", setFunc, 'passwordError');
    }

    if ('code' in formData && !formData.code.match(/^\d{6}$/)) {
        changeState("Введен неверный код", setFunc, 'codeError');
        validationResult = false;
    } else {
        changeState("", setFunc, 'codeError');
    }

    return validationResult;
}

export const months = [
    'Января',
    'Февраля',
    'Марта',
    'Апреля',
    'Мая',
    'Июня',
    'Июля',
    'Августа',
    'Сентября',
    'Октября',
    'Ноября',
    'Декабря',
]