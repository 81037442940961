import axios from "axios";
import useUtil from "../useUtil";
import { tempWalletHistory, tempWalletInfo } from "../../components/account/accountComponents/additional/tempData";
import { AgentsInfo, MutationParams } from "./types";
import { useMutation, useQueryClient } from "react-query";
import { queryKeys } from "./constants";
import { useCallback } from "react";

type MakePayoutParams = {
  Specification: 1,
  PhoneNumber: string,
  SbpMemberId: string,
  Amount: number
  AdditionalInfo?: {
    Name: string,
    MiddleName: string,
    Surname: string,
  },
}

export type GetMasterWalletParams = {
  Employers: string[],
  ApprovementStatus?: 1 | 2 | 3,
}

export const useWalletApi = () => {

  const { handleUserRequest, errorParser } = useUtil();
  const _baseAPI = 'https://exeedservice.ru/apicall/';
  const queryClient = useQueryClient();

  const getWalletInfo = async () => {
    // return handleDefaultUserAxiosRequestWithToken('user/info', {}, _baseAPI, 'token', (result) => {
    //     if (result.data.user.email) {
    //         localStorage.setItem('user', JSON.stringify(result.data.user));
    //     }
    // });

    return tempWalletInfo;
  }

  const getAgents = async (payload: { Specification: number }): Promise<AgentsInfo> => {
    const result = await handleUserRequest({
      path: 'Payout/GetAgents',
      method: 'post',
      payload,
      tokenType: 'token',
      isNewQuery: true,
    });

    return result?.items;
  }

  const makePayout = async (payload: MakePayoutParams) => {
    return await handleUserRequest({
      path: 'Payout/MakePayout',
      method: 'post',
      payload,
      tokenType: 'token',
      isNewQuery: true,
    });
  }

  const getWallet = async () => {
    return await handleUserRequest({
      path: 'Wallet/GetWallet',
      method: 'post',
      tokenType: 'token',
      isNewQuery: true,
    });
  }

  const getMasterWallet = async (payload: GetMasterWalletParams) => {
    return await handleUserRequest({
      path: 'InternalAPI/Wallet/GetTransactions',
      method: 'post',
      payload,
      tokenType: 'masterTokenInt',
      isNewQuery: true,
    });
  }

  const { mutate: changeTransactionStatusMutation } = useMutation(async ({ payload }: any) => {
    try {
      return await handleUserRequest({
        path: 'InternalAPI/Wallet/ConfirmTransactions',
        tokenType: 'masterTokenInt',
        payload: {
          Items: [payload]
        },
        isNewQuery: true,
      });
    } catch (error: unknown) {
      throw error;
    }
  },
    {
      onSuccess: () => queryClient.invalidateQueries([queryKeys.GET_MASTERS_WALLET_INFO]),
      onError: (data) => { },
    })

  const changeTransactionStatus = useCallback((params: MutationParams<any>) => {
    changeTransactionStatusMutation(params, {
      onSuccess: params.onSuccessAction,
      onError: (error: unknown) => {
        params.onErrorAction?.(errorParser(error))
      },
    })
  }, [changeTransactionStatusMutation]);

  return {
    getWalletInfo,
    getWallet,
    getAgents,
    makePayout,
    getMasterWallet,
    changeTransactionStatus
  }
}
