import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { TAnyArrayOfObj } from '../types/declarations';
import { BASE_URL } from './api/constants';
import { useQueryClient } from 'react-query';

type HandleUserRequest = (requestData: {
  path: string;
  method?: 'post' | 'get';
  payload?: any;
  tokenType?: string;
  customFunction?: (result: any) => void;
  baseUrl?: string;
  isNewQuery?: boolean;
}) => any;

type HeaderCreator = (tokenType?: string) => {
  'Content-Type': 'application/json';
  Authorization?: string;
};

const useUtil = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const compareArrays = (
    oldArray: TAnyArrayOfObj,
    newArray: TAnyArrayOfObj,
    property: string,
  ): boolean => {
    if (oldArray.length !== newArray.length) {
      return false;
    } else {
      for (let i = 0; i < oldArray.length; i++) {
        if (oldArray[i][property] !== newArray[i][property]) {
          return false;
        }
      }
    }
    return true;
  };

  const handleDefaultMasterAxiosRequestWithToken = async (
    path: string,
    payload: any,
    basePath: string,
  ) => {
    try {
      const result = await axios.post(`${basePath}${path}`, payload, {
        headers: _headerCreator('masterToken'),
      });
      return result.data;
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        localStorage.removeItem('masterToken');
        navigate('/masterpage/');
      }
    }
  };
  const handleDefaultUserAxiosRequestWithToken = async (
    //@ts-ignore
    path,
    //@ts-ignore
    payload,
    //@ts-ignore
    basePath,
    //@ts-ignore
    tokenType = 'token',
    //@ts-ignore
    customFunction,
  ) => {
    try {
      const result = await axios.post(`${basePath}${path}`, payload, {
        headers: _headerCreator(tokenType),
      });
      if (customFunction) {
        customFunction(result);
      }
      return result.data;
    } catch (error: any) {
      if (error.response.status === 403 || error.response.status === 401) {
        localStorage.removeItem(tokenType);
        sessionStorage.removeItem('createData');
        if (tokenType === 'masterToken') {
          navigate('/masterpage');
        } else {
          navigate('/');
        }
      }
    }
  };

  const handleUserRequest: HandleUserRequest = async (requestData) => {
    const {
      baseUrl = BASE_URL,
      path,
      payload = {},
      tokenType,
      customFunction,
      method = 'post',
      isNewQuery = false,
    } = requestData;

    try {
      let result: any = {};
      switch (method) {
        case 'post':
          result = await axios[method](`${baseUrl}${path}`, payload ?? {}, {
            headers: _headerCreator(tokenType),
          });
          break;
        case 'get':
          result = await axios[method](`${baseUrl}${path}`, { headers: _headerCreator(tokenType) });
          break;
        default:
          break;
      }
      if (typeof customFunction === 'function') {
        customFunction(result);
      }
      //@ts-ignore
      return result.data === '' ? result.status : result.data;
    } catch (error: any) {
      if (
        error.response.status === 400 &&
        (error.response.data.ErrorReason === 'User not found' ||
          error.response.data.ErrorReason === 'Token is expired' ||
          error.response.data.ErrorReason === 'Token could not be validated') &&
        tokenType
      ) {
        queryClient.resetQueries();
        localStorage.removeItem(tokenType);
        sessionStorage.removeItem('createData');
        if (tokenType === 'masterToken') {
          navigate('/masterpage');
        } else {
          navigate('/');
        }
        return;
      }
      if (isNewQuery) {
        throw new Error(error.response?.data?.ErrorReason);
      } else {
        if (error.response?.data?.ErrorReason.includes('FrontEnd general error')) {
          throw new Error('Произошла ошибка');
        }
        return errorParser(error.response?.data?.ErrorReason);
      }
    }
  };

  const errorParser = (error: unknown): { error: string } => {
    let errorMessage = 'Произошла ошибка';
    if (typeof error === 'string') {
      switch (true) {
        case error === 'Password is wrong':
          errorMessage = 'Введен неверный логин или пароль';
          break;
        case error === `Email hasn't still confirmed`:
          errorMessage = 'Необходимо подтвердить почту';
          break;
        case error === `User not found`:
          errorMessage = 'Пользователь не найден';
          break;
        case error === `Redirection wasn't found`:
          errorMessage = 'Опрос не найден';
          break;
        case error === `Duplicate survey`:
          errorMessage = 'Данный опрос уже был пройден';
          break;
        case error === 'Money is not enough for transaction':
          errorMessage = 'Средств недостаточно или они заблокированны';
          break;
        case error === 'New Phone number already exists in database':
          errorMessage = 'Данный номер уже исполльзуется';
          break;
        case !!error.match(/^User with phonenumber/):
          errorMessage = 'Пользователь с такой почтой уже зарегистрирован';
          break;
        default:
          break;
      }
    }
    return { error: errorMessage };
  };

  const _headerCreator: HeaderCreator = (tokenType?: string) => {
    const token = tokenType ? localStorage.getItem(tokenType) : null;
    return {
      ...(token && { Authorization: `Bearer ${token}` }),
      'Content-Type': 'application/json',
    };
  };

  return {
    compareArrays,
    handleDefaultMasterAxiosRequestWithToken,
    handleDefaultUserAxiosRequestWithToken,
    handleUserRequest,
    errorParser,
  };
};

export default useUtil;
