import useUtil from "../useUtil";

type VideoInfo = {
    id: string,
    name: string,
    previewUrl: string,
}

export type VideoList = VideoInfo & {
    url: string,
}

export const useVideoApi = () => {

    const { handleUserRequest } = useUtil();

    const getVideoList = async () => {
        const videoInfoRaw = await handleUserRequest({
            path: 'Education/VideoService/GetVideoList',
            tokenType: 'token',
        });
        const videoInfo: VideoInfo[] = videoInfoRaw?.items?.reverse() ? videoInfoRaw?.items.reverse() : [];

        return await Promise.all(videoInfo.map(async (video) => {
            const videoLink = await handleUserRequest({
                path: 'Education/VideoService/GetVideo',
                tokenType: 'token',
                payload: {
                    items: [video.id]
                }
            });
            return {
                ...video,
                url: videoLink?.items[0]?.url ? videoLink?.items[0]?.url : '',
            }
        }))
    }

    return {
        getVideoList,
    }
}
