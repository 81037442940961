import { useCallback } from 'react';
import axios from "axios";
import useUtil from "../useUtil";
import { MutationParams, RatingInfo, UserInfo } from "./types";
import { TEMP_RATING_DATA, TEMP_USER_DATA, queryKeys } from "./constants";
import { useMutation, useQueryClient } from "react-query";
import { GetRatingParams } from '../data/useRating';

export const useUserApi = () => {

  const { handleUserRequest, errorParser } = useUtil();
  const queryClient = useQueryClient();

  const changeUserSuccess = () => {
    queryClient.invalidateQueries([queryKeys.GET_USER_INFO])
  }
  const getUserInfo = async (): Promise<UserInfo> => {
    const result = await handleUserRequest({
      path: 'UserInformation/GetUserInfo',
      method: 'get',
      tokenType: 'token',
      isNewQuery: true,
    });
    return {
      id: result.userId ? result.userId : '',
      role: result?.profile[0]?.role ? result?.profile[0]?.role : 5,
      userName: result.fullName,
      userPhone: result.contacts?.phoneNumber ? result.contacts?.phoneNumber : '',
      userEmail: result.contacts?.email ? result.contacts?.email : '',
      userDC: result?.profile[0]?.dealerId ? result?.profile[0]?.dealerId : -1,
      emailVerified: result.contacts?.emailConfirmed ? result.contacts?.emailConfirmed : false,
      phoneVerified: result.contacts?.phoneConfirmed ? result.contacts?.phoneConfirmed : false,
      contract: result?.profile[0]?.contract?.link ? result?.profile[0]?.contract?.link : '',
      inn: result?.profile[0]?.inn ? result?.profile[0]?.inn : '',
      isModerated: result?.profile[0]?.moderated ? result?.profile[0]?.moderated : false,
      avatar: result.imageSource?.link ? result.imageSource?.link : null,
      wallet: result.wallet?.deposit,
      reportPeriodActionState: result?.profile[0]?.reportPeriods?.[0]?.reportPeriodActionState,
      reportDate: result?.profile[0]?.reportPeriods?.[0]?.reportDate,
    }

    // return  TEMP_USER_DATA;
  }

  const { mutate: changeUserInfoMutation } = useMutation(async ({ payload }: any) => {
    try {
      return await handleUserRequest({
        path: 'UserInformation/ChangeUserInfo',
        tokenType: 'token',
        payload,
        isNewQuery: true,
      });
    } catch (error: unknown) {
      throw error;
    }
  },
    {
      onSuccess: changeUserSuccess,
      onError: (data) => { },
    })

  const changeUserInfo = useCallback((params: MutationParams<any>) => {
    changeUserInfoMutation(params, {
      onSuccess: params.onSuccessAction,
      onError: (error: unknown) => {
        params.onErrorAction?.(errorParser(error))
      },
    })
  }, [changeUserInfoMutation]);

  const getRating = async (payload: GetRatingParams): Promise<RatingInfo | null> => {
    if (!payload.id) {
      return null;
    }
    const result = await axios.post(`https://exeedservice.ru/apicall/newmaster/getMonthlyReport`, payload);
    return result.data !== 'No data' ? result.data : null;
    //  return TEMP_RATING_DATA; 
  }

  const getDetailedRating = async (payload: { id: string }): Promise<any> => {
    const result = await axios.post(`https://exeedservice.ru/apicall/newmaster/getDetailedRating`, payload);
    return result.data;
  }

  return {
    getUserInfo,
    changeUserInfo,
    getRating,
    getDetailedRating,
  }
}
