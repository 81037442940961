import React from 'react';

import '../../style/components/forms/input.scss';
import { SxProps, TextField, Theme } from '@mui/material';

interface IInputProps {
  label?: string | JSX.Element;
  placeholder?: string;
  value: string | number;
  setValue?: (value: string) => void;
  customClassName?: string;
  error?: string | boolean;
  type?: string;
  inputFor?: string;
  disabled?: boolean;
  required?: boolean;
  isNew?: boolean;
  sx?: SxProps<Theme>;
}

const Input = (props: IInputProps) => {
  const {
    label = '',
    placeholder = '',
    value,
    setValue = () => null,
    customClassName = '',
    error = '',
    type = 'text',
    inputFor = 'text',
    disabled = false,
    required = false,
    isNew = false,
    sx,
  } = props;

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    switch (inputFor) {
      case 'text':
        setValue(e.target.value);
        break;
      case 'vin':
        if (e.target.value.match(/^[a-zA-Z0-9]*$/) && e.target.value.length <= 17) {
          setValue(e.target.value.toLocaleUpperCase());
        }
        break;
      case 'number':
        if (e.target.value.match(/^\d*$/)) {
          setValue(e.target.value);
        }
        break;
      case 'passportSeries':
        if (e.target.value.match(/^\d*$/) && e.target.value.length <= 4) {
          setValue(e.target.value);
        }
        break;
      case 'passportNumber':
        if (e.target.value.match(/^\d*$/) && e.target.value.length <= 6) {
          setValue(e.target.value);
        }
        break;
      case 'phone':
        let plainValue = e.target.value.replace(/\D+/g, '');
        if (!plainValue) {
          return;
        }

        switch (plainValue.charAt(0)) {
          case '7':
            break;
          case '8':
            plainValue = `7${plainValue.slice(1)}`;
            break;
          default:
            plainValue = `7${plainValue}`;
            break;
        }

        if (plainValue.length <= 11) {
          switch (plainValue.length) {
            case 1:
              setValue(`+7`);
              break;
            case 2:
            case 3:
            case 4:
              setValue(`+7 (${plainValue.slice(1)}`);
              break;
            case 5:
            case 6:
            case 7:
              setValue(`+7 (${plainValue.slice(1, 4)}) ${plainValue.slice(4)}`);
              break;
            case 8:
            case 9:
              setValue(
                `+7 (${plainValue.slice(1, 4)}) ${plainValue.slice(4, 7)}-${plainValue.slice(7)}`,
              );
              break;
            case 10:
            case 11:
              setValue(
                `+7 (${plainValue.slice(1, 4)}) ${plainValue.slice(4, 7)}-${plainValue.slice(
                  7,
                  9,
                )}-${plainValue.slice(9, 11)}`,
              );
              break;
            default:
              break;
          }
        }
        break;
      default:
        setValue(e.target.value);
        break;
    }
  };

  return (
    <div
      className={`input-container ${customClassName}`}
      style={{ ...(isNew && { width: 'auto' }) }}
    >
      {required ? (
        <div style={{ paddingLeft: '10px', marginTop: '15px' }}>
          <span style={{ display: 'inline-block' }}>{label}</span>
          <span style={{ paddingLeft: '0.2em', color: 'red', display: 'inline-block' }}>*</span>
        </div>
      ) : (
        <span>{label}</span>
      )}
      {isNew ? (
        <TextField
          label={placeholder}
          variant="outlined"
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeInput(e)}
          disabled={disabled}
          error={!!error}
          helperText={error}
          sx={sx}
        />
      ) : (
        <>
          <input
            className={`${error && 'input-error'}`}
            type={type}
            value={value}
            placeholder={placeholder}
            onChange={(e) => onChangeInput(e)}
            disabled={disabled}
          />
          <span className="span-error">{error}</span>
        </>
      )}
    </div>
  );
};

export default Input;
