import { useCallback } from 'react';
import { useMutation, useQueryClient } from "react-query";
import useUtil from "../useUtil";
import { queryKeys } from "./constants";
import { AddDocumentParams, DeleteDocumentParams, DocumentsList, DocumentsListShort, GetDocumentsParams } from '../data/useDocuments';

export const useDocumentsApi = () => {

  const { handleUserRequest } = useUtil();
  const queryClient = useQueryClient();

  const documentChangeSuccess = () => {
    queryClient.invalidateQueries([queryKeys.GET_DOCUMENTS])
  }

  const { mutate: addDocumentMutation } = useMutation(async ({ payload }: AddDocumentParams) => {
    try {
      return await handleUserRequest({
        path: 'Document/AddDocument',
        payload,
        tokenType: 'token',
        isNewQuery: true,
      });
    } catch (error: unknown) {
      throw error;
    }
  },
    {
      onSuccess: documentChangeSuccess,
      onError: () => console.error('Не удалось добавить документ'),
    }
  )

  const { mutate: deleteDocumentMutation } = useMutation(async ({ payload }: DeleteDocumentParams) => {
    try {
      return await handleUserRequest({
        path: 'Document/RemoveDocument',
        payload,
        tokenType: 'token',
        isNewQuery: true,
      });
    } catch (error: unknown) {
      throw error;
    }
  },
    {
      onSuccess: documentChangeSuccess,
      onError: () => console.error('Не удалось удалить документ'),
    }
  )

  const addDocument = useCallback((params: AddDocumentParams) => {
    addDocumentMutation(params, {
      onSuccess: params.onSuccessAction,
      onError: params.onErrorAction,
    })
  }, [addDocumentMutation]);

  const deleteDocument = useCallback((params: DeleteDocumentParams) => {
    deleteDocumentMutation(params, {
      onSuccess: params.onSuccessAction,
      onError: params.onErrorAction,
    })
  }, [addDocumentMutation]);

  const getDocuments = async (payload: GetDocumentsParams) => {
    const documentsListShort: DocumentsListShort = await handleUserRequest({
      path: 'Document/GetDocumentsList',
      payload,
      tokenType: 'token',
      isNewQuery: true,
    });

    const documentsListIds = documentsListShort.items.map((document) => document.id);

    const documentsList: DocumentsList = await handleUserRequest({
      path: 'Document/GetDocument',
      tokenType: 'token',
      isNewQuery: true,
      payload: { items: documentsListIds }
    });
    return documentsList;
  }

  return {
    addDocument,
    getDocuments,
    deleteDocument,
  }
}
