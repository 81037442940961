type Color = React.CSSProperties['color'];

declare module '@mui/material/styles' {
  interface Palette {
    black: Color;
    white: Color;
    main: Color;
    mainBckgrnd: Color;
    mainBckgrndHover: Color;
    whiteShade: Color;
    bckgrndColor: Color;
    sidebarBckgrnd: Color;
    sidebarBckgrndHover: Color;
    sidebarBckgrndThumb: Color;
    errorBorder: Color;
    errorBorderDark: Color;
    errorBckgrnd: Color;
    errorBckgrndDark: Color;
    infoBorder: Color;
    infoBckgrnd: Color;
    successGreen: Color;
    successGreenLight: Color;
    tableBorderColor: Color;
    link: Color;
    linkHover: Color;
    linkBckgrnd: Color;
    linkBckgrndHover: Color;
    modalHeaderLightGrey: Color;
  }
}

export const palette = {
  black: '#000',
  white: '#fff',

  main: '#c78e66',
  mainBckgrnd: '#ffefe4',
  mainBckgrndHover: '#f9dac5',

  whiteShade: '#c0bebe',

  bckgrndColor: 'rgb(247, 247, 247)',
  sidebarBckgrnd: '#242424',
  sidebarBckgrndHover: '#484848',
  sidebarBckgrndThumb: '#7a7a7a',

  errorBorder: '#c90000',
  errorBorderDark: '#5e0000',
  errorBckgrnd: '#fff2f2',
  errorBckgrndDark: '#f8c3c3',

  infoBorder: '#3c3600',
  infoBckgrnd: '#fbffe7',

  successGreen: '#007400',
  successGreenLight: '#00c000',
  tableBorderColor: '#dee2e6',

  link: '#005B84',
  linkHover: '#007db8',
  linkBckgrnd: '#e0f5ff',
  linkBckgrndHover: '#95ddff',

  modalHeaderLightGrey: '#e2e2e2',
};
