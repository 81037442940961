import { useQuery } from 'react-query';
import { queryKeys } from '../api/constants';
import useApiQueries from '../useApiQueries';

export const useUser = (token: string | null) => {
  const { getUserInfo } = useApiQueries();
  return useQuery({
    queryKey: [queryKeys.GET_USER_INFO, token],
    queryFn: getUserInfo,
  })
}