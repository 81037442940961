import React, { useState } from 'react';

import FormContainer from './FormContainer';
import Input from '../../forms/Input';
import CodeForm from './CodeForm';

import useApiQueries from '../../../hooks/useApiQueries';
import { registerData } from '../../data/formsInitialinfo';
import { IEntryProps, TFormRegisterData, TButtonStatuses } from '../../../types/declarations';
import { changeState } from '../../forms/formActions';

import '../../../style/components/entryScreen/forms/registerForm.scss';

const RegisterForm = ({ setActiveForm }: IEntryProps) => {

  const [formData, setFormData] = useState<TFormRegisterData>(registerData);
  const [confirmStage, setConfirmStage] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState<TButtonStatuses>('idle');
  const { registerUser, verificationAppeal } = useApiQueries();

  const createFooter = () => {
    return (
      <div className='register-footer'>
        <div className="text">
          Уже зарегистрированы?
        </div>
        <div
          className="link"
          onClick={() => setActiveForm(1)}
        >
          Войти тут
        </div>
      </div>
    )
  }

  const submitForm = () => {
    changeState('', setFormData, 'commonError');
    setLoadingStatus('loading');
    registerUser({
      FullName: formData.name,
      PhoneNumber: formData.phone,
      Email: formData.email,
      Role: 5,
      Password: formData.password,
    })
      .then((res) => {
        if (res.error || res !== 200) {
          changeState(res.error ?? 'Произошла ошибка', setFormData, 'commonError');
          setLoadingStatus('error');
          return;
        }
        verificationAppeal({
          ClientType: 1,
          Specification: 1,
          Email: formData.email,
          ServiceType: 2
        }).then((res) => {
          if (res !== 200) {
            changeState('Произошла ошибка', setFormData, 'commonError');
            setLoadingStatus('error');
            return;
          }
          setConfirmStage(true);
          setLoadingStatus('success');
        }).catch(() => changeState('Произошла ошибка', setFormData, 'commonError'));
      })
      .catch(() => changeState('Произошла ошибка', setFormData, 'commonError'))
      .finally(() => setLoadingStatus('idle'));
  }

  const returnBack = () => {
    setConfirmStage(false);
  }

  const closeConfirm = () => {
    setActiveForm(1);
  }

  return (
    <>
      {confirmStage ?
        <CodeForm
          returnBackFunc={returnBack}
          confirmedFunc={closeConfirm}
          confirmedText='Вы успешно зарегистрировались! Просьба пройти по ссылке, отправленной на указанный почтовый адрес для его подтверждения.'
          header={'Регистрация'}
          tempConfirmed={true}
        />
        :
        <FormContainer
          formData={formData}
          setFormData={setFormData}
          loadingStatus={loadingStatus}
          setLoadingStatus={setLoadingStatus}
          addSmartCaptcha={true}
          submitForm={submitForm}
          header={'Регистрация'}
          footer={createFooter()}
          buttonTitle={'Зарегистрироваться'}
        >
          <Input
            label={'Имя'}
            value={formData.name}
            setValue={(newValue) => changeState(newValue, setFormData, 'name')}
            placeholder={'Введите ваше имя'}
            customClassName={'es-input'}
            error={formData.nameError}
          />
          <Input
            label={'Почта'}
            value={formData.email}
            setValue={(newValue) => changeState(newValue, setFormData, 'email')}
            placeholder={'Введите e-mail'}
            customClassName={'es-input'}
            error={formData.emailError}
            type='email'
          />
          <Input
            label={'Телефон'}
            value={formData.phone}
            setValue={(newValue) => changeState(newValue, setFormData, 'phone')}
            placeholder={'Введите телефон'}
            customClassName={'es-input'}
            error={formData.phoneError}
            inputFor='phone'
          />
          <Input
            label={'Пароль'}
            value={formData.password}
            setValue={(newValue) => changeState(newValue, setFormData, 'password')}
            placeholder={'Введите пароль'}
            customClassName={'es-input'}
            type='password'
            error={formData.passwordError}
          />
          <div className="rf-policy">
            <div
              className={`rf-checkbox ${formData.policy ? 'active' : ''}`}
              onClick={() => changeState(!formData.policy, setFormData, 'policy')}
            >
              {
                formData.policy &&
                <div className="rf-checkbox-container" id='rf-checkbox'>
                  <div className="checkmark"></div>
                </div>
              }
            </div>
            <label
              htmlFor='rf-checkbox'
              className="rf-policy-text"
              onClick={() => changeState(!formData.policy, setFormData, 'policy')}
            >
              Я согласен(-на) с
            </label>
            <label className="rf-policy-link">Политикой конфиденциальности</label>
          </div>
          <span className="span-error">{formData.policyError}</span>
        </FormContainer>
      }
    </>
  )
}

export default RegisterForm
