import React from 'react'

import { IEntryProps } from '../../../types/declarations'

import '../../../style/components/entryScreen/forms/introForm.scss';

const IntroForm = ({ setActiveForm }: IEntryProps) => {
    return (
        <div className='intro-container'>
            <div className="intro-header">
                {`Добро пожаловать \n в мир EXEED`}
            </div>
            <div className="intro-buttons">
                <div
                    className="intro-auth-button"
                    onClick={() => setActiveForm(1)}
                >
                    Войти
                </div>
                <div
                    className="intro-register-button"
                    onClick={() => setActiveForm(2)}
                >
                    Зарегистрироваться
                </div>
            </div>
        </div>
    )
}

export default IntroForm
